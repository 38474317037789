

export const NRCan = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      width="100%"
      height="100%"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 496.689 38.011"
    >
      <g id="g3">
        <g id="g5">
          <path id="rect7" fill="#ed1c24" d="M0 0h19.006v38.008H0z"></path>
        </g>
        <path
          id="path11"
          fill="#ed1c24"
          d="m51.879 18.997-.41-.204c-.639-.3-.538-.837-.439-1.198.1-.358 1.165-4.094 1.165-4.094s-2.792.626-3.443.727c-.519.08-.7-.16-.858-.52-.16-.359-.838-1.994-.838-1.994s-3.418 3.783-3.492 3.851c-.658.598-1.296.139-1.176-.559.108-.636 1.756-8.202 1.756-8.202s-1.817 1.078-2.413 1.437c-.6.358-.919.3-1.238-.279-.319-.578-2.559-5.069-2.559-5.069h.005S35.7 7.384 35.38 7.962c-.319.579-.64.637-1.238.279s-2.414-1.437-2.414-1.437 1.645 7.565 1.756 8.202c.118.699-.521 1.157-1.178.559a985 985 0 0 1-3.492-3.851s-.679 1.636-.837 1.994c-.161.359-.34.598-.859.52-.653-.101-3.556-.727-3.556-.727s1.178 3.736 1.278 4.094c.099.36.2.898-.44 1.198l-.411.204z"
        ></path>
        <path
          id="path13"
          fill="#ed1c24"
          d="m23.987 18.997-.646.315s6.469 5.593 6.689 5.791c.218.199.638.373.319 1.333-.319.958-.779 2.253-.779 2.253s5.986-1.215 6.625-1.356c.638-.141 1.177.079 1.177.718 0 .638-.26 7.044-.26 7.044h1.642s-.259-6.407-.259-7.044c0-.639.539-.859 1.177-.718.638.14 6.624 1.356 6.624 1.356s-.459-1.295-.78-2.253c-.318-.96.1-1.134.32-1.333.219-.198 6.688-5.791 6.688-5.791l-.646-.315z"
        ></path>
        <path
          id="path15"
          fill="#ed1c24"
          d="M38.754 35.096s-.259-6.407-.259-7.044c0-.639.539-.859 1.177-.718.639.14 6.624 1.356 6.624 1.356s-.458-1.295-.779-2.253c-.319-.96.099-1.134.319-1.333.22-.198 6.688-5.791 6.688-5.791l-1.057-.519c-.639-.3-.538-.837-.439-1.198.1-.358 1.165-4.094 1.165-4.094s-2.791.626-3.443.727c-.519.08-.7-.16-.858-.52-.161-.359-.838-1.994-.838-1.994s-3.418 3.783-3.492 3.851c-.659.598-1.296.139-1.176-.559.108-.636 1.756-8.202 1.756-8.202s-1.816 1.078-2.413 1.437c-.6.358-.919.3-1.238-.279-.319-.578-2.559-5.069-2.559-5.069h.005s-2.239 4.491-2.558 5.069c-.32.579-.64.637-1.238.279-.599-.358-2.414-1.437-2.414-1.437s1.645 7.565 1.754 8.202c.119.699-.52 1.157-1.177.559a985 985 0 0 1-3.492-3.851s-.678 1.636-.837 1.994c-.16.359-.34.598-.86.52-.652-.101-3.555-.727-3.555-.727s1.178 3.736 1.278 4.094c.099.36.2.898-.44 1.198l-1.057.519s6.469 5.593 6.689 5.791c.218.199.638.373.319 1.333-.319.958-.779 2.253-.779 2.253s5.986-1.215 6.625-1.356c.638-.141 1.177.079 1.177.718 0 .638-.26 7.044-.26 7.044z"
        ></path>
        <g id="g17">
          <path
            id="rect19"
            fill="#ed1c24"
            d="M56.988 0h19.005v38.008H56.988z"
          ></path>
        </g>
        <g id="g23">
          <path
            id="path25"
            d="M308.689.596h6.481c2.584 0 4.187 1.407 4.187 3.608 0 1.659-.733 3.029-2.393 3.492v.037c1.603.309 1.93 1.486 2.065 2.837.116 1.351.039 2.854.812 3.801h-2.047c-.522-.562-.229-2.046-.503-3.395-.191-1.351-.521-2.489-2.297-2.489h-4.478v5.884h-1.829V.596zm5.65 6.348c1.718 0 3.188-.444 3.188-2.45 0-1.351-.732-2.355-2.453-2.355h-4.554v4.806h3.819z"
          ></path>
          <path
            id="path27"
            d="M330.146 11.207c-.447 2.2-2.03 3.395-4.266 3.395-3.184 0-4.688-2.199-4.786-5.25 0-2.989 1.969-5.189 4.687-5.189 3.533 0 4.612 3.299 4.52 5.711h-7.47c-.058 1.736.927 3.28 3.105 3.28 1.35 0 2.298-.656 2.586-1.948h1.624zm-1.586-2.78c-.074-1.562-1.25-2.817-2.874-2.817-1.716 0-2.739 1.292-2.854 2.817z"
          ></path>
          <path
            id="path29"
            d="M332.999 11.226c.059 1.466 1.329 1.93 2.68 1.93 1.024 0 2.412-.232 2.412-1.485 0-1.274-1.617-1.486-3.262-1.853-1.616-.367-3.256-.908-3.256-2.818 0-2.005 1.984-2.835 3.723-2.835 2.2 0 3.957.694 4.092 3.125h-1.644c-.112-1.273-1.231-1.679-2.312-1.679-.985 0-2.124.27-2.124 1.275 0 1.175 1.735 1.37 3.26 1.735 1.64.367 3.262.908 3.262 2.835 0 2.373-2.219 3.146-4.246 3.146-2.236 0-4.128-.907-4.225-3.377z"
          ></path>
          <path
            id="path31"
            d="M342.645 11.226c.059 1.466 1.332 1.93 2.684 1.93 1.023 0 2.409-.232 2.409-1.485 0-1.274-1.62-1.486-3.26-1.853-1.62-.367-3.26-.908-3.26-2.818 0-2.005 1.989-2.835 3.725-2.835 2.201 0 3.957.694 4.09 3.125h-1.64c-.114-1.273-1.234-1.679-2.314-1.679-.984 0-2.123.27-2.123 1.275 0 1.175 1.735 1.37 3.262 1.735 1.64.367 3.26.908 3.26 2.835 0 2.373-2.222 3.146-4.244 3.146-2.24 0-4.13-.907-4.229-3.377h1.642v.001z"
          ></path>
          <path
            id="path33"
            d="M355.593 4.165c3.162 0 4.843 2.295 4.843 5.229 0 2.913-1.681 5.208-4.843 5.208-3.165 0-4.844-2.296-4.844-5.208 0-2.934 1.679-5.229 4.844-5.229m0 8.991c1.717 0 3.104-1.351 3.104-3.762 0-2.431-1.387-3.783-3.104-3.783-1.719 0-3.106 1.353-3.106 3.783-.001 2.411 1.387 3.762 3.106 3.762"
          ></path>
          <path
            id="path35"
            d="M370.624 14.372h-1.546V12.79h-.037c-.693 1.236-1.813 1.813-3.243 1.813-2.623 0-3.433-1.505-3.433-3.646v-6.56h1.642v6.753c0 1.215.772 2.007 2.023 2.007 1.986 0 2.952-1.33 2.952-3.126V4.396h1.642z"
          ></path>
          <path
            id="path37"
            d="M373.033 4.396h1.545v2.103h.036c.791-1.601 1.892-2.393 3.668-2.334V5.9c-2.644 0-3.609 1.506-3.609 4.034v4.438h-1.642V4.396z"
          ></path>
          <path
            id="path39"
            d="M387.986 7.599c-.231-2.373-2.044-3.434-4.302-3.434-3.164 0-4.707 2.354-4.707 5.344 0 2.972 1.618 5.094 4.667 5.094 2.512 0 4.032-1.447 4.398-3.878h-1.676c-.211 1.504-1.161 2.431-2.741 2.431-2.082 0-2.915-1.813-2.915-3.647 0-2.025.734-3.898 3.109-3.898 1.35 0 2.215.734 2.468 1.988z"
          ></path>
          <path
            id="path41"
            d="M398.388 11.207c-.444 2.2-2.027 3.395-4.268 3.395-3.182 0-4.687-2.199-4.784-5.25 0-2.989 1.967-5.189 4.69-5.189 3.529 0 4.611 3.299 4.514 5.711h-7.465c-.061 1.736.924 3.28 3.107 3.28 1.35 0 2.292-.656 2.584-1.948h1.621zm-1.583-2.78c-.078-1.562-1.255-2.817-2.875-2.817-1.716 0-2.739 1.292-2.854 2.817z"
          ></path>
          <path
            id="path43"
            d="M401.242 11.226c.057 1.466 1.332 1.93 2.683 1.93 1.021 0 2.409-.232 2.409-1.485 0-1.274-1.62-1.486-3.26-1.853-1.621-.367-3.262-.908-3.262-2.818 0-2.005 1.989-2.835 3.724-2.835 2.2 0 3.957.694 4.091 3.125h-1.638c-.119-1.273-1.236-1.679-2.316-1.679-.984 0-2.123.27-2.123 1.275 0 1.175 1.735 1.37 3.26 1.735 1.642.367 3.258.908 3.258 2.835 0 2.373-2.217 3.146-4.244 3.146-2.234 0-4.128-.907-4.225-3.377h1.644v.001z"
          ></path>
          <path
            id="path45"
            d="M415.25 4.396h1.542v1.582h.037c.696-1.236 1.814-1.813 3.244-1.813 2.624 0 3.431 1.506 3.431 3.646v6.561h-1.642V7.618c0-1.216-.77-2.007-2.023-2.007-1.984 0-2.952 1.333-2.952 3.126v5.634h-1.639V4.396z"
          ></path>
          <path
            id="path47"
            d="M434.812 14.333c-.289.172-.655.269-1.176.269-.851 0-1.391-.463-1.391-1.544-.903 1.061-2.123 1.544-3.511 1.544-1.815 0-3.301-.811-3.301-2.798 0-2.257 1.681-2.739 3.379-3.067 1.812-.348 3.357-.232 3.357-1.466 0-1.428-1.178-1.661-2.219-1.661-1.391 0-2.414.425-2.489 1.892h-1.641c.096-2.47 2.007-3.337 4.226-3.337 1.795 0 3.742.405 3.742 2.739v5.133c0 .773 0 1.12.521 1.12.135 0 .289-.02.501-.097zm-2.661-5.114c-.638.463-1.87.483-2.972.675-1.08.193-2.008.578-2.008 1.793 0 1.081.928 1.467 1.928 1.467 2.16 0 3.049-1.351 3.049-2.258V9.219z"
          ></path>
          <path
            id="path49"
            d="M438.614 4.396h1.986v1.446h-1.986v6.194c0 .753.212.888 1.235.888h.751v1.448h-1.254c-1.698 0-2.374-.348-2.374-2.18v-6.35h-1.698V4.396h1.698v-2.99h1.642z"
          ></path>
          <path
            id="path51"
            d="M450.674 14.372h-1.544V12.79h-.041c-.694 1.236-1.812 1.813-3.237 1.813-2.625 0-3.438-1.505-3.438-3.646v-6.56h1.642v6.753c0 1.215.772 2.007 2.024 2.007 1.99 0 2.951-1.33 2.951-3.126V4.396h1.644v9.976z"
          ></path>
          <path
            id="path53"
            d="M453.083 4.396h1.543v2.103h.041c.791-1.601 1.891-2.393 3.665-2.334V5.9c-2.644 0-3.606 1.506-3.606 4.034v4.438h-1.643z"
          ></path>
          <path
            id="path55"
            d="M468.074 11.207c-.44 2.2-2.023 3.395-4.264 3.395-3.182 0-4.689-2.199-4.784-5.25 0-2.989 1.969-5.189 4.688-5.189 3.531 0 4.611 3.299 4.516 5.711h-7.467c-.057 1.736.928 3.28 3.105 3.28 1.351 0 2.297-.656 2.587-1.948h1.618zm-1.579-2.78c-.08-1.562-1.26-2.817-2.876-2.817-1.718 0-2.743 1.292-2.855 2.817z"
          ></path>
          <path id="path57" d="M470.025.596h1.638v13.776h-1.638z"></path>
          <path id="path59" d="M474.307.596h1.64v13.776h-1.64z"></path>
          <path
            id="path61"
            d="M487.001 11.207c-.443 2.2-2.024 3.395-4.262 3.395-3.184 0-4.687-2.199-4.786-5.25 0-2.989 1.969-5.189 4.688-5.189 3.531 0 4.611 3.299 4.516 5.711h-7.466c-.058 1.736.928 3.28 3.105 3.28 1.351 0 2.297-.656 2.585-1.948h1.62zm-1.582-2.78c-.078-1.562-1.251-2.817-2.875-2.817-1.716 0-2.741 1.292-2.854 2.817z"
          ></path>
          <path
            id="path63"
            d="M488.218 11.226c.097 2.471 1.986 3.376 4.225 3.376 2.027 0 4.246-.772 4.246-3.145 0-1.93-1.623-2.47-3.26-2.836-1.526-.365-3.262-.559-3.262-1.735 0-1.004 1.139-1.274 2.125-1.274 1.078 0 2.197.405 2.314 1.678h1.642c-.137-2.43-1.894-3.125-4.093-3.125-1.736 0-3.723.83-3.723 2.835 0 1.911 1.642 2.45 3.261 2.817 1.641.366 3.26.578 3.26 1.854 0 1.253-1.392 1.484-2.413 1.484-1.35 0-2.62-.463-2.681-1.93z"
          ></path>
          <path
            id="path65"
            d="M318.373 28.056c-.385-1.949-1.945-2.933-3.856-2.933-3.283 0-4.672 2.704-4.672 5.576 0 3.146 1.389 5.77 4.689 5.77 2.413 0 3.799-1.699 3.976-3.975h1.832c-.345 3.433-2.489 5.517-5.962 5.517-4.285 0-6.367-3.16-6.367-7.176 0-3.993 2.277-7.253 6.521-7.253 2.876 0 5.25 1.542 5.674 4.475h-1.835z"
          ></path>
          <path
            id="path67"
            d="M331.186 37.645c-.291.175-.657.272-1.176.272-.851 0-1.392-.464-1.392-1.544-.905 1.063-2.122 1.544-3.51 1.544-1.815 0-3.303-.812-3.303-2.801 0-2.255 1.681-2.736 3.379-3.067 1.812-.346 3.359-.229 3.359-1.466 0-1.426-1.18-1.658-2.219-1.658-1.391 0-2.414.424-2.491 1.891h-1.641c.096-2.469 2.007-3.337 4.226-3.337 1.793 0 3.742.403 3.742 2.738v5.132c0 .771 0 1.12.521 1.12.135 0 .289-.018.501-.096v1.272zm-2.664-5.111c-.636.461-1.872.482-2.971.674-1.08.192-2.007.579-2.007 1.794 0 1.083.927 1.467 1.93 1.467 2.16 0 3.047-1.354 3.047-2.257z"
          ></path>
          <path
            id="path69"
            d="M332.71 27.709h1.542v1.582h.041c.692-1.235 1.811-1.812 3.238-1.812 2.622 0 3.438 1.505 3.438 3.647v6.559h-1.642v-6.754c0-1.214-.774-2.006-2.026-2.006-1.99 0-2.951 1.332-2.951 3.124v5.636h-1.642V27.71h.002z"
          ></path>
          <path
            id="path71"
            d="M352.272 37.645c-.289.175-.655.272-1.178.272-.848 0-1.388-.464-1.388-1.544-.905 1.063-2.122 1.544-3.512 1.544-1.812 0-3.299-.812-3.299-2.801 0-2.255 1.679-2.736 3.375-3.067 1.814-.346 3.359-.229 3.359-1.466 0-1.426-1.179-1.658-2.219-1.658-1.391 0-2.412.424-2.49 1.891h-1.644c.1-2.469 2.011-3.337 4.227-3.337 1.796 0 3.743.403 3.743 2.738v5.132c0 .771 0 1.12.521 1.12.137 0 .291-.018.503-.096zm-2.662-5.111c-.638.461-1.873.482-2.971.674-1.082.192-2.009.579-2.009 1.794 0 1.083.927 1.467 1.933 1.467 2.16 0 3.047-1.354 3.047-2.257z"
          ></path>
          <path
            id="path73"
            d="M362.713 37.685h-1.642v-1.353h-.038c-.539 1.104-1.812 1.585-3.184 1.585-3.07 0-4.593-2.433-4.593-5.25s1.505-5.19 4.554-5.19c1.021 0 2.488.384 3.221 1.562h.039v-5.134h1.642zm-4.631-1.215c2.199 0 3.048-1.892 3.048-3.763 0-1.968-.89-3.782-3.147-3.782-2.236 0-2.989 1.912-2.989 3.856-.001 1.875.965 3.689 3.088 3.689"
          ></path>
          <path
            id="path75"
            d="M374.076 37.645c-.29.175-.659.272-1.18.272-.848 0-1.386-.464-1.386-1.544-.908 1.063-2.125 1.544-3.513 1.544-1.812 0-3.299-.812-3.299-2.801 0-2.255 1.676-2.736 3.374-3.067 1.815-.346 3.359-.229 3.359-1.466 0-1.426-1.178-1.658-2.221-1.658-1.388 0-2.411.424-2.489 1.891h-1.641c.098-2.469 2.007-3.337 4.226-3.337 1.796 0 3.742.403 3.742 2.738v5.132c0 .771 0 1.12.521 1.12.137 0 .291-.018.503-.096v1.272zm-2.663-5.111c-.64.461-1.873.482-2.974.674-1.08.192-2.008.579-2.008 1.794 0 1.083.928 1.467 1.931 1.467 2.161 0 3.049-1.354 3.049-2.257v-1.679z"
          ></path>
        </g>
        <g id="g77">
          <path
            id="path79"
            d="M113.816.596h1.951l7.253 11.17h.039V.596h1.737v13.776h-2.007l-7.197-11.056h-.039v11.056h-1.737z"
          ></path>
          <path
            id="path81"
            d="M136.333 14.333c-.289.172-.655.269-1.176.269-.849 0-1.389-.463-1.389-1.544-.907 1.061-2.121 1.544-3.512 1.544-1.813 0-3.298-.811-3.298-2.798 0-2.257 1.679-2.739 3.375-3.067 1.814-.348 3.358-.232 3.358-1.466 0-1.428-1.176-1.661-2.219-1.661-1.387 0-2.411.425-2.488 1.892h-1.64c.096-2.47 2.005-3.337 4.226-3.337 1.793 0 3.744.405 3.744 2.739v5.133c0 .773 0 1.12.521 1.12.134 0 .289-.02.501-.097zm-2.662-5.114c-.636.463-1.871.483-2.971.675-1.081.193-2.007.578-2.007 1.793 0 1.081.926 1.467 1.931 1.467 2.16 0 3.047-1.351 3.047-2.258z"
          ></path>
          <path
            id="path83"
            d="M140.134 4.396h1.987v1.446h-1.987v6.194c0 .753.212.888 1.235.888h.752v1.448h-1.255c-1.697 0-2.373-.348-2.373-2.18v-6.35h-1.698V4.396h1.698v-2.99h1.64z"
          ></path>
          <path
            id="path85"
            d="M152.193 14.372h-1.544V12.79h-.037c-.695 1.236-1.813 1.813-3.242 1.813-2.623 0-3.434-1.505-3.434-3.646v-6.56h1.64v6.753c0 1.215.771 2.007 2.025 2.007 1.987 0 2.952-1.33 2.952-3.126V4.396h1.641z"
          ></path>
          <path
            id="path87"
            d="M154.604 4.396h1.545v2.103h.037c.792-1.601 1.891-2.393 3.667-2.334V5.9c-2.643 0-3.608 1.506-3.608 4.034v4.438h-1.64V4.396z"
          ></path>
          <path
            id="path89"
            d="M169.923 14.333c-.289.172-.654.269-1.177.269-.848 0-1.388-.463-1.388-1.544-.908 1.061-2.124 1.544-3.512 1.544-1.813 0-3.301-.811-3.301-2.798 0-2.257 1.679-2.739 3.377-3.067 1.814-.348 3.357-.232 3.357-1.466 0-1.428-1.178-1.661-2.218-1.661-1.39 0-2.412.425-2.49 1.892h-1.641c.097-2.47 2.007-3.337 4.227-3.337 1.794 0 3.742.405 3.742 2.739v5.133c0 .773 0 1.12.522 1.12.134 0 .29-.02.501-.097zm-2.662-5.114c-.637.463-1.871.483-2.971.675-1.08.193-2.005.578-2.005 1.793 0 1.081.925 1.467 1.929 1.467 2.161 0 3.049-1.351 3.049-2.258V9.219z"
          ></path>
          <path id="path91" d="M171.544.596h1.642v13.776h-1.642z"></path>
          <path
            id="path93"
            d="M181.365.596h6.482c2.586 0 4.188 1.407 4.188 3.608 0 1.659-.733 3.029-2.393 3.492v.037c1.6.309 1.93 1.486 2.064 2.837.117 1.351.039 2.854.812 3.801h-2.045c-.522-.562-.231-2.046-.503-3.395-.191-1.351-.521-2.489-2.296-2.489h-4.478v5.884h-1.831zm5.653 6.348c1.718 0 3.183-.444 3.183-2.45 0-1.351-.733-2.355-2.45-2.355h-4.553v4.806h3.82z"
          ></path>
          <path
            id="path95"
            d="M202.819 11.207c-.444 2.2-2.024 3.395-4.265 3.395-3.181 0-4.688-2.199-4.784-5.25 0-2.989 1.968-5.189 4.689-5.189 3.531 0 4.612 3.299 4.514 5.711h-7.465c-.06 1.736.924 3.28 3.106 3.28 1.35 0 2.294-.656 2.586-1.948h1.619zm-1.583-2.78c-.076-1.562-1.253-2.817-2.874-2.817-1.717 0-2.74 1.292-2.854 2.817z"
          ></path>
          <path
            id="path97"
            d="M205.674 11.226c.059 1.466 1.333 1.93 2.684 1.93 1.024 0 2.41-.232 2.41-1.485 0-1.274-1.618-1.486-3.261-1.853-1.619-.367-3.26-.908-3.26-2.818 0-2.005 1.989-2.835 3.725-2.835 2.201 0 3.956.694 4.091 3.125h-1.64c-.117-1.273-1.234-1.679-2.315-1.679-.983 0-2.122.27-2.122 1.275 0 1.175 1.734 1.37 3.26 1.735 1.642.367 3.262.908 3.262 2.835 0 2.373-2.219 3.146-4.246 3.146-2.238 0-4.13-.907-4.228-3.377h1.641v.001z"
          ></path>
          <path
            id="path99"
            d="M218.621 4.165c3.163 0 4.844 2.295 4.844 5.229 0 2.913-1.68 5.208-4.844 5.208-3.166 0-4.846-2.296-4.846-5.208.002-2.934 1.682-5.229 4.846-5.229m0 8.991c1.716 0 3.107-1.351 3.107-3.762 0-2.431-1.391-3.783-3.107-3.783-1.719 0-3.107 1.353-3.107 3.783.002 2.411 1.389 3.762 3.107 3.762"
          ></path>
          <path
            id="path101"
            d="M233.65 14.372h-1.542V12.79h-.039c-.696 1.236-1.812 1.813-3.242 1.813-2.622 0-3.434-1.505-3.434-3.646v-6.56h1.642v6.753c0 1.215.768 2.007 2.025 2.007 1.986 0 2.952-1.33 2.952-3.126V4.396h1.638z"
          ></path>
          <path
            id="path103"
            d="M236.063 4.396h1.543v2.103h.039c.789-1.601 1.891-2.393 3.664-2.334V5.9c-2.644 0-3.606 1.506-3.606 4.034v4.438h-1.642V4.396z"
          ></path>
          <path
            id="path105"
            d="M249.317 7.599c-.25-1.253-1.119-1.988-2.471-1.988-2.374 0-3.107 1.873-3.107 3.898 0 1.832.83 3.647 2.915 3.647 1.582 0 2.526-.927 2.741-2.431h1.677c-.365 2.431-1.892 3.878-4.4 3.878-3.047 0-4.668-2.122-4.668-5.094 0-2.989 1.545-5.344 4.709-5.344 2.255 0 4.068 1.061 4.301 3.434z"
          ></path>
          <path
            id="path107"
            d="M261.417 11.207c-.447 2.2-2.027 3.395-4.266 3.395-3.182 0-4.687-2.199-4.785-5.25 0-2.989 1.97-5.189 4.688-5.189 3.533 0 4.614 3.299 4.518 5.711h-7.468c-.059 1.736.926 3.28 3.104 3.28 1.354 0 2.3-.656 2.588-1.948h1.622v.001zm-1.584-2.78c-.078-1.562-1.253-2.817-2.875-2.817-1.718 0-2.739 1.292-2.856 2.817z"
          ></path>
          <path
            id="path109"
            d="M264.271 11.226c.059 1.466 1.331 1.93 2.682 1.93 1.021 0 2.41-.232 2.41-1.485 0-1.274-1.618-1.486-3.26-1.853-1.62-.367-3.26-.908-3.26-2.818 0-2.005 1.987-2.835 3.724-2.835 2.199 0 3.956.694 4.09 3.125h-1.637c-.119-1.273-1.238-1.679-2.318-1.679-.984 0-2.123.27-2.123 1.275 0 1.175 1.736 1.37 3.26 1.735 1.641.367 3.261.908 3.261 2.835 0 2.373-2.219 3.146-4.244 3.146-2.235 0-4.129-.907-4.227-3.377h1.643v.001z"
          ></path>
          <path
            id="path111"
            d="M123.521 28.056c-.384-1.949-1.947-2.933-3.859-2.933-3.279 0-4.669 2.704-4.669 5.576 0 3.146 1.391 5.77 4.688 5.77 2.412 0 3.8-1.699 3.976-3.975h1.831c-.346 3.433-2.489 5.517-5.961 5.517-4.282 0-6.367-3.16-6.367-7.176 0-3.993 2.277-7.253 6.521-7.253 2.875 0 5.249 1.542 5.673 4.475h-1.833z"
          ></path>
          <path
            id="path113"
            d="M136.333 37.645c-.289.175-.655.272-1.176.272-.849 0-1.389-.464-1.389-1.544-.907 1.063-2.121 1.544-3.512 1.544-1.813 0-3.298-.812-3.298-2.801 0-2.255 1.679-2.736 3.375-3.067 1.814-.346 3.358-.229 3.358-1.466 0-1.426-1.176-1.658-2.219-1.658-1.387 0-2.411.424-2.488 1.891h-1.64c.096-2.469 2.005-3.337 4.226-3.337 1.793 0 3.744.403 3.744 2.738v5.132c0 .771 0 1.12.521 1.12.134 0 .289-.018.501-.096zm-2.662-5.111c-.636.461-1.871.482-2.971.674-1.081.192-2.007.579-2.007 1.794 0 1.083.926 1.467 1.931 1.467 2.16 0 3.047-1.354 3.047-2.257z"
          ></path>
          <path
            id="path115"
            d="M137.856 27.709h1.544v1.582h.038c.695-1.235 1.812-1.812 3.24-1.812 2.625 0 3.436 1.505 3.436 3.647v6.559h-1.64v-6.754c0-1.214-.773-2.006-2.026-2.006-1.987 0-2.952 1.332-2.952 3.124v5.636h-1.64z"
          ></path>
          <path
            id="path117"
            d="M157.42 37.645c-.29.175-.655.272-1.176.272-.849 0-1.389-.464-1.389-1.544-.908 1.063-2.122 1.544-3.513 1.544-1.813 0-3.297-.812-3.297-2.801 0-2.255 1.678-2.736 3.375-3.067 1.813-.346 3.357-.229 3.357-1.466 0-1.426-1.179-1.658-2.219-1.658-1.389 0-2.411.424-2.489 1.891h-1.64c.095-2.469 2.005-3.337 4.226-3.337 1.794 0 3.744.403 3.744 2.738v5.132c0 .771 0 1.12.521 1.12.135 0 .29-.018.502-.096v1.272zm-2.662-5.111c-.636.461-1.871.482-2.972.674-1.079.192-2.005.579-2.005 1.794 0 1.083.926 1.467 1.929 1.467 2.162 0 3.048-1.354 3.048-2.257z"
          ></path>
          <path
            id="path119"
            d="M167.859 37.685h-1.64v-1.353h-.04c-.541 1.104-1.813 1.585-3.183 1.585-3.067 0-4.592-2.433-4.592-5.25s1.505-5.19 4.553-5.19c1.022 0 2.489.384 3.222 1.562h.04v-5.134h1.64zm-4.631-1.215c2.199 0 3.048-1.892 3.048-3.763 0-1.968-.887-3.782-3.144-3.782-2.238 0-2.99 1.912-2.99 3.856 0 1.875.964 3.689 3.086 3.689"
          ></path>
          <path
            id="path121"
            d="M179.224 37.645c-.29.175-.656.272-1.178.272-.849 0-1.389-.464-1.389-1.544-.908 1.063-2.122 1.544-3.513 1.544-1.813 0-3.297-.812-3.297-2.801 0-2.255 1.678-2.736 3.375-3.067 1.813-.346 3.357-.229 3.357-1.466 0-1.426-1.176-1.658-2.219-1.658-1.388 0-2.411.424-2.489 1.891h-1.64c.095-2.469 2.005-3.337 4.226-3.337 1.793 0 3.744.403 3.744 2.738v5.132c0 .771 0 1.12.521 1.12.134 0 .29-.018.502-.096zm-2.663-5.111c-.636.461-1.872.482-2.972.674-1.08.192-2.005.579-2.005 1.794 0 1.083.926 1.467 1.93 1.467 2.161 0 3.048-1.354 3.048-2.257z"
          ></path>
        </g>
      </g>
    </svg>
  )
}