

export const RMIB = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="60"
      viewBox="0 0 48 60"
    >
      <g
        id="Language-Selection"
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
      >
        <g id="kmi_desktop_language" transform="translate(-696 -260)">
          <g id="Group-2" transform="translate(630 260)">
            <g id="logo_EN" transform="translate(66)">
              <path id="Rectangle-8" fill="#FFF" d="M0 0h47.273v60H0z"></path>
              <g id="Page-1" transform="translate(4.575 4.5)">
                <path
                  id="Fill-1"
                  fill="#0056EF"
                  d="M.243 39.132h39.032V.797H.243z"
                ></path>
                <path
                  id="Fill-2"
                  fill="#FFF"
                  d="m10.608.399.891.956 1.296 1.594 1.457 1.992.73 1.116.728 1.116.648 1.275.648 1.196.567 1.275.405 1.275.324 1.275.161 1.275v3.985l-.08 1.196-.163 1.115-.161 1.036-.244.957-.323.956-.324.957-.324.876-.405.877-.486.877-1.053 1.753-1.133 1.913-.648.956-.486 1.036-.405 1.036-.243.957-.243.956-.162.956-.08.877v2.391l.08 1.116.162.797.081.239 2.106-.16v-.239l-.081-.558v-.876l.161-1.116.163-1.275.405-1.355.242-.718.324-.717.405-.717.405-.717 1.134-1.515 1.133-1.354 1.053-1.196.972-1.036 1.863-1.753 1.62-1.594.728-.797.648-.797.648-.956.486-.957.485-1.195.406-1.275.324-1.515.242-1.753.244-1.275.323-1.275.486-1.276.567-1.115.648-1.196.729-1.036.729-1.036.89-.956.89-.877.973-.797.972-.717 1.053-.638.971-.478 1.053-.478.971-.24.973-.159.566-.08.324-.159.243-.16.162-.159v-.398l-.08-.24-.163-.238-.405-.399-.404-.398-.405-.24-.082-.08L10.285 0z"
                ></path>
                <path
                  id="Fill-3"
                  fill="#0056EF"
                  d="M4.049 40.487H0v11.715h2.348v-5.18h2.673l.324.08.323.16.325.238.323.24.243.398 2.51 4.064h2.754L8.665 47.5l-.162-.239-.243-.16-.162-.239-.162-.079-.162-.16-.243-.079-.243-.08-.243-.08v-.08l.73-.08.647-.238.567-.24.486-.318.405-.399.324-.398.161-.478.082-.478v-.558l-.163-.398-.161-.4-.243-.318-.243-.319-.324-.24-.405-.238-.405-.24-.972-.238-1.134-.16-1.134-.079zm-1.7 1.434h2.267l.81.08.647.08.568.16.485.159.324.239.324.318.162.32v.398l-.08.398-.163.399-.243.319-.405.238-.567.16-.567.16-.728.159H2.348V41.92z"
                ></path>
                <path
                  id="Fill-4"
                  fill="#0056EF"
                  d="m28.829 40.487-5.101 9.723-5.183-9.723h-3.888v11.715h2.349V42.081l5.506 10.121h2.349l5.426-10.121h.08v10.121h2.35V40.487z"
                ></path>
                <path
                  id="Fill-5"
                  fill="#0056EF"
                  d="M36.927 52.202h2.349V40.487h-2.349z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}