import { useSearchParams } from "react-router-dom"
import "./App.css"
import "./ReleaseNotes.css"


const EN = {
  // "1.6.1": [
  //   "Fixed an issue where observation heatmap wasn't always refreshed",
  //   "Fixed an issue where observation cluster stamp wasn't always from the most recent observation"
  // ],
  "1.6.0": [
    "Aurora observations can now be shared on map view!",
    "Added cloudiness filter option to aurora alerts (finally)",
    "The magnetometer raw data modal now has an automatic interval fetch"
  ],
  "1.5.0": [
    "Magnetometer raw data can now be viewed by pressing magnetometer icons on map view",
    "Added a list of recent solar flares on solar animation tab",
    "Short-term Kp forecast chart now shows data from the past",
    "Fixed an edge case that sometimes prevented tab change"
  ],
  "1.4.1": [
    "Fixed an edge case that caused cloudiness data not to be fetched on initial startup"
  ],
  "1.4.0": [
    "Added Japanese language support",
    "Improved chart interactions",
    "Animated cloudiness bar chart",
    "Improved moonrise and moonset time precision",
    "Fixed unnecessary server error popup that sometimes appeared on initial startup",
    "Enhanced solar animation corrupt image check on Android to catch partially downloaded images"
  ],
  "1.3.1": [
    "Fixed solar animation pinch-to-zoom gesture activation issue",
    "High font size doesn't prevent proceeding from welcome screen anymore",
    "Fixed a bug that caused text to not appear on some Android custom ROMs"
  ],
  "1.3.0": [
    "Added solar wind and solar flare alerts! They can be enabled from app settings",
    "Added more alert condition customizations",
    "Some settings now have a location-based auto adjust mode",
    "Drawer menu button press area isn't partially blocked anymore",
    "Header does not block solar animation pinch to zoom gesture anymore on iOS",
    "Fixed a bug which sometimes prevented charts from rendering on iOS",
    "Coarse location permissions now work on Android",
    "Fixed an edge case which sometimes prevented auto interval fetch from working",
    "Fixed side drawer when using right-to-left languages",
    "Settings UI rework"
  ],
  "1.2.0": [
    "Solar animation can now be zoomed in with pinch gesture!",
    "Added support for horizons other than north or south in certain locations",
    "Improved magnetometer marker rendering performance",
    "Fixed color palette change issue in x-ray chart",
    "Fixed server maintenance error not showing properly"
  ],
  "1.1.1": [
    "Fixed a bug that prevented receiving notifications if the app remained unopened after updating from version 1.0.2",
  ],
  "1.1.0": [
    "Charts are now interactive! Hold down your finger on top of a chart and the chart becomes interactive",
    "Improved chart rendering performance",
    "Reduced aurora alert notification delay and improved notification delivery reliability",
    "Resolved a problem with solar wind and x-ray auto interval fetching",
    "Minor styling in help guides"
  ],
  "1.0.2": [
    "Fixed AM/PM formatting error in short-term Kp forecast chart"
  ],
  "1.0.1": [
    "Fixed in-app purchase confirmation delay",
    "Fixed solar animation auto interval fetch",
    "Fixed a visual bug in the loading indicator"
  ],
  "1.0.0": [
    "Initial release"
  ]
}


const FI: typeof EN = {
  "1.6.0": [
    "Revontulihavaintoja voi nyt jakaa karttanäkymässä!",
    "Lisätty revontulihälytyksiin pilvisyyssuodatinasetus (vihdoinkin!)",
    "Lisätty automaattinen intervallinouto magnetometrien raakadatanäkymään"
  ],
  "1.5.0": [
    "Magnetometerien raakadataa voi nyt katsoa painamalla magnetometrien ikoneita kartalla",
    "Lisätty listaus viimeaikaisista roihupurkauksista aurinkoanimaatiovälilehdelle",
    "Lyhyen aikavälin Kp-ennusteessa näkee nyt myös dataa menneisyydestä",
    "Korjattu rajatapaus, joka esti välilehden vaihtamisen"
  ],
  "1.4.1": [
    "Korjattu rajatapaus, joka esti pilvisyysdatanoudon ensimmäisellä käynnistyskerralla"
  ],
  "1.4.0": [
    "Lisätty tuki Japanin kielelle",
    "Lisätty animaatio pilvisyysdatan palkkeihin ja kuvaajien data-ikkunoihin",
    "Parannettu kuun nousu- ja laskuaikojen tarkkuutta",
    "Korjattu aiheeton palvelinvirheilmoitus, joka saattoi ilmestyä ensimmäisellä käynnistyskerralla",
    "Parannettu aurinkoanimaation korruptoituneiden kuvien tarkastusta Androidilla"
  ],
  "1.3.1": [
    "Korjattu aurinkoanimaation zoomauseleen aktivointiin liittyvä virhe",
    "Suuri fonttikoko ei enää estä etenemästä tervetuloa-näkymästä",
    "Korjattu virhe, joka esti tekstiä näkymästä joillakin Androidin custom ROMeilla"
  ],
  "1.3.0": [
    "Lisätty aurinkotuuli- ja roihupurkaushälytykset! Ne saa päälle sovelluksen asetuksista",
    "Lisätty uusia säätimiä hälytysasetuksiin",
    "Lisätty sijaintipohjainen automaattisäätöominaisuus tiettyihin asetuksiin",
    "Korjattu virhe, joka pienensi sivumenun avauspainikkeen painallusaluetta",
    "Otsikkoalue ei enää estä aurinkoanimaation zoomauselettä iOS:llä",
    "Korjattu virhe, joka esti kuvaajia välillä piirtymästä iOS:llä",
    "Korjattu virhe, joka esti karkean sijainnin sijaintioikeuksia toimimasta Androidilla",
    "Korjattu rajatapaus, joka esti automaattista intervallinoutoa välillä toimimasta",
    "Paranneltu asetusten käyttöliittymää"
  ],
  "1.2.0": [
    "Aurinkoanimaatiota voi nyt zoomata sormien nipistys-/levittämiseleellä!",
    "Parannettu magnetometrien piirtonopeutta",
    "Lisätty tuki väli-ilmansuuntien horisonteille",
    "Korjattu väripaletin vaihto-ongelma röntgensäteilykuvaajassa",
    "Palvelimen huoltokatkoilmoitus näkyy nyt muista virheistä eriteltynä"
  ],
  "1.1.1": [
    "Korjattu bugi, joka esti ilmoitusten vastaanottamisen, jos sovellusta ei avannut 1.0.2-version päivityksen jälkeen"
  ],
  "1.1.0": [
    "Kuvaajat ovat nyt interaktiivisia! Voit tarkastella lukemia tarkemmin pitämällä kuvaajaa hetken aikaa pohjassa",
    "Parannettu kuvaajien piirtonopeutta",
    "Vähennetty revontulihälytysten viivettä ja parannettu ilmoitusten toimitusvarmuutta",
    "Korjattu bugi aurinkotuuli- ja röntgensäteilydatan intervallinoudossa",
    "Parannettu muutamia ohjevalikkoja visuaalisesti"
  ],
  "1.0.2": [
    "Korjattu ap./ip. muotoilu lyhyen aikavälin Kp-ennusteen kuvaajassa"
  ],
  "1.0.1": [
    "Korjattu viive sovelluksen sisäisissä ostoksissa",
    "Korjattu aurinkoanimaation automaattinen intervallinouto",
    "Korjattu visuaalinen virhe latausindikaattorissa"
  ],
  "1.0.0": [
    "Ensimmäinen julkaisu"
  ]
}


const LANGUAGES = {
  en: EN,
  fi: FI
}


const VersionContainer = ({
  version,
  date,
  language
}: {
  version: keyof typeof LANGUAGES["en"],
  date: string,
  language: keyof typeof LANGUAGES
}) => {
  const rows = LANGUAGES[language][version];
  return (
    <>
      <div>
        <h1>
          {version}
        </h1>
        <h4>
          {date}
        </h4>

        <div className="release-notes-main-wrapper">
          {rows.map((row) => (
            <div className="release-notes-item-wrapper">
              <span>
                {"-"}
              </span>
              <span className="release-notes-item-span">
                {row}
              </span>
            </div>
          ))}
        </div>
      </div>
      {version !== "1.0.0" ? <div className="horizontal-divider-line" /> : null}
    </>
  )
}


const parseLanguage = (language: string | null) => {
  if (!language) return "en"

  if (["fi", "en"].includes(language)) {
    return language as keyof typeof LANGUAGES
  }

  return "en"
}


export const ReleaseNotes = () => {
  const [searchParams] = useSearchParams();
  const queryLanguage = searchParams.get("language");
  const language = parseLanguage(queryLanguage)

  return (
    <div className="basic-canvas">
      <div className="basic-column">
        <VersionContainer
          version={"1.6.0"}
          date={"2025-03-12"}
          language={language}
        />
        <VersionContainer
          version={"1.5.0"}
          date={"2025-02-10"}
          language={language}
        />
        <VersionContainer
          version={"1.4.1"}
          date={"2024-08-07"}
          language={language}
        />
        <VersionContainer
          version={"1.4.0"}
          date={"2024-08-06"}
          language={language}
        />
        <VersionContainer
          version={"1.3.1"}
          date={"2024-05-19"}
          language={language}
        />
        <VersionContainer
          version={"1.3.0"}
          date={"2024-04-08"}
          language={language}
        />
        <VersionContainer
          version={"1.2.0"}
          date={"2024-02-07"}
          language={language}
        />
        <VersionContainer
          version={"1.1.1"}
          date={"2023-12-16"}
          language={language}
        />
        <VersionContainer
          version={"1.1.0"}
          date={"2023-12-05"}
          language={language}
        />
        <VersionContainer
          version={"1.0.2"}
          date={"2023-09-01"}
          language={language}
        />
        <VersionContainer
          version={"1.0.1"}
          date={"2023-08-25"}
          language={language}
        />
        <VersionContainer
          version={"1.0.0"}
          date={"2023-08-25"}
          language={language}
        />
      </div>
    </div>
  )
}